var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "landing-central-card",
    [
      _c(
        "v-card",
        {
          staticClass: "sign_up-card",
          class: _vm.$vuetify.breakpoint.smAndUp ? "pa-3" : "pa-0",
          style: _vm.cardStyles,
          attrs: {
            flat: "",
            elevation: _vm.elevation,
            "min-height": _vm.cardHeight
          }
        },
        [
          _vm.loading ? _c("v-card-loader") : _vm._e(),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: {
                    autocomplete: "off",
                    transition: "slide-y-reverse-transition"
                  },
                  nativeOn: {
                    keydown: function($event) {
                      if (
                        !$event.type.indexOf("key") &&
                        _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                      ) {
                        return null
                      }
                      return _vm.handleEnterKey($event)
                    }
                  },
                  model: {
                    value: _vm.valid,
                    callback: function($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid"
                  }
                },
                [
                  _c(
                    "v-layout",
                    {
                      attrs: {
                        row: "",
                        wrap: "",
                        "justify-center": "",
                        "gap-xs-3": ""
                      }
                    },
                    [
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "", md10: "" } },
                        [
                          _c("h1", { staticClass: "starling-h1" }, [
                            _vm._v(
                              _vm._s(_vm.$t("public.signup.account.title"))
                            )
                          ])
                        ]
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "", md10: "" } },
                        [
                          _c("registration-field-agreement", {
                            model: {
                              value: _vm.form.agreement,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "agreement", $$v)
                              },
                              expression: "form.agreement"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "", md10: "" } },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                row: "",
                                wrap: _vm.$vuetify.breakpoint.xsOnly,
                                "justify-center": "",
                                "gap-xs-2": ""
                              }
                            },
                            [
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "social_login",
                                      attrs: {
                                        block: "",
                                        outline: "",
                                        disabled: !_vm.form.agreement
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.federatedSignIn(
                                            "SignInWithApple"
                                          )
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "$vuetify.icons.starling_social_apple"
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                  Apple\n                "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-flex",
                                { attrs: { xs12: "", sm4: "" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "social_login",
                                      attrs: {
                                        block: "",
                                        outline: "",
                                        disabled: !_vm.form.agreement
                                      },
                                      on: {
                                        click: function($event) {
                                          return _vm.federatedSignIn("Google")
                                        }
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(
                                          "$vuetify.icons.starling_social_google"
                                        )
                                      ]),
                                      _vm._v(
                                        "\n                  Google\n                "
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              false
                                ? _c(
                                    "v-flex",
                                    { attrs: { xs12: "", sm4: "" } },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "social_login",
                                          attrs: {
                                            block: "",
                                            outline: "",
                                            disabled: !_vm.form.agreement
                                          },
                                          on: {
                                            click: function($event) {
                                              return _vm.federatedSignIn(
                                                "Facebook"
                                              )
                                            }
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "$vuetify.icons.starling_social_facebook"
                                            )
                                          ]),
                                          _vm._v(
                                            "\n                  Facebook\n                "
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("v-flex", { attrs: { xs10: "", sm8: "", md7: "" } }, [
                        _c("p", { staticClass: "border-with-text" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.$t("common.actions.or")))
                          ])
                        ])
                      ]),
                      _c(
                        "v-flex",
                        { attrs: { xs12: "", sm11: "", md10: "" } },
                        [
                          _c("registration-field-email", {
                            attrs: {
                              disabled: !_vm.form.agreement,
                              "should-focus": false
                            },
                            on: { "form-validate": _vm.onFormValidate },
                            model: {
                              value: _vm.form.email,
                              callback: function($$v) {
                                _vm.$set(_vm.form, "email", $$v)
                              },
                              expression: "form.email"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm.error
                    ? _c(
                        "v-layout",
                        {
                          attrs: {
                            row: "",
                            wrap: "",
                            "justify-center": "",
                            "align-center": "",
                            "pb-2": ""
                          }
                        },
                        [
                          _c(
                            "v-flex",
                            { attrs: { xs10: "", sm8: "", md6: "" } },
                            [
                              _c("div", { staticClass: "error--text" }, [
                                _vm._v(_vm._s(_vm.error))
                              ])
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-actions",
            { staticClass: "pa-0 pt-3" },
            [
              _c(
                "v-layout",
                {
                  attrs: {
                    row: "",
                    wrap: "",
                    "justify-center": "",
                    "align-center": "",
                    "gap-xs-3": ""
                  }
                },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "", "pt-3": "" } },
                    [
                      _c(
                        "primary-button",
                        {
                          ref: "signUpBtn",
                          attrs: {
                            id: "sign-up",
                            type: "button",
                            large: "",
                            loading: _vm.loading,
                            disabled: !_vm.valid || _vm.loading
                          },
                          on: { click: _vm.onSignUp }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("public.signup.account.buttons.signup")
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", "text-center": "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { flat: "", large: "", round: "" },
                          on: { click: _vm.onCancel }
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("common.actions.cancel")) +
                              "\n          "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }