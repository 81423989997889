<template>
  <landing-central-card>
    <v-card flat class="sign_up-card" :elevation="elevation" :min-height="cardHeight" :style="cardStyles" :class="$vuetify.breakpoint.smAndUp ? 'pa-3' : 'pa-0'">
      <v-card-loader v-if="loading"></v-card-loader>

      <v-card-text class="pa-0">
        <v-form
            @keydown.enter.native="handleEnterKey"
            autocomplete="off"
            transition="slide-y-reverse-transition"
            ref="form"
            v-model="valid">
          <v-layout row wrap justify-center gap-xs-3>
            <v-flex xs12 sm11 md10>
              <h1 class="starling-h1">{{$t('public.signup.account.title')}}</h1>
            </v-flex>

            <v-flex xs12 sm11 md10>
              <registration-field-agreement v-model="form.agreement"/>
            </v-flex>

            <v-flex xs12 sm11 md10>
              <v-layout row :wrap="$vuetify.breakpoint.xsOnly" justify-center gap-xs-2>
                <v-flex xs12 sm4>
                  <v-btn class="social_login" block outline @click="federatedSignIn('SignInWithApple')" :disabled="!form.agreement">
                    <v-icon>$vuetify.icons.starling_social_apple</v-icon>
                    Apple
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm4>
                  <v-btn class="social_login" block outline @click="federatedSignIn('Google')" :disabled="!form.agreement">
                    <v-icon>$vuetify.icons.starling_social_google</v-icon>
                    Google
                  </v-btn>
                </v-flex>
                <v-flex xs12 sm4 v-if="false">
                  <v-btn class="social_login" block outline @click="federatedSignIn('Facebook')" :disabled="!form.agreement">
                    <v-icon>$vuetify.icons.starling_social_facebook</v-icon>
                    Facebook
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-flex>

            <v-flex xs10 sm8 md7>
              <p class="border-with-text"><span>{{ $t('common.actions.or') }}</span></p>
            </v-flex>

            <v-flex xs12 sm11 md10>
              <registration-field-email v-model="form.email"
                                        :disabled="!form.agreement"
                                        :should-focus="false"
                                        @form-validate="onFormValidate"/>
            </v-flex>
          </v-layout>

          <v-layout row wrap justify-center align-center pb-2 v-if="error">
            <v-flex xs10 sm8 md6>
              <div class="error--text">{{ error }}</div>
            </v-flex>
          </v-layout>
        </v-form>
      </v-card-text>

      <v-card-actions class="pa-0 pt-3">
        <v-layout row wrap justify-center align-center gap-xs-3>
          <v-flex xs12 text-center pt-3>
            <primary-button
                id="sign-up"
                ref="signUpBtn"
                type="button"
                large
                :loading="loading"
                :disabled="!valid || loading"
                @click="onSignUp">
              {{ $t('public.signup.account.buttons.signup') }}
            </primary-button>
          </v-flex>

          <v-flex xs12 text-center>
            <v-btn
                flat large round
                @click="onCancel">
              {{ $t('common.actions.cancel') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-actions>
    </v-card>
  </landing-central-card>
</template>

<script>
import { mapState } from 'vuex';
import LandingCentralCard from '@/views/landing/landing-central-card.vue';
import PrimaryButton from '@/views/components/button/primary-button.vue';
import RegistrationFieldAgreement from '@/views/landing/register/components/registration-field-agreement.vue';
import RegistrationFieldEmail from '@/views/landing/register/components/registration-field-email.vue';
import vCardLoader from '@/views/components/loader/v-card-loader.vue';

export default {
  components: {
    LandingCentralCard,
    PrimaryButton,
    RegistrationFieldAgreement,
    RegistrationFieldEmail,
    vCardLoader,
  },
  props: [ 'elevation', 'cardHeight', 'cardStyles' ],
  data() {
    return {
      valid: false,
      error: null,
      loading: false,
      form: {
        email: this.$route.params.email || null,
        password: null,
        passwordConfirmation: null,
        agreement: null,
      },
    };
  },
  computed: {
    ...mapState({
      partner: state => {
        return state.identity;
      },
      locale() {
        return localStorage.getItem('currLang');
      },
    }),
  },
  methods: {
    onFormValidate(fieldname) {
      if (this.$refs.form) {
        if (fieldname) {
          const field = this.$refs.form.$data.inputs.find(i => i.$attrs.name === fieldname);
          if (field) {
            field.validate(true);
          }
        } else {
          this.$refs.form.validate();
        }
      }
    },
    handleEnterKey() {
      if (this.valid) {
        this.onSignUp();
      }
    },
    onSignUp() {
      this.loading = true;
      this.$store.dispatch('identity/signUp', {
        username: this.form.email,
        password: this.form.password,
        attributes: { email: this.form.email },
        clientMetadata: {
          subdomain: this.partner?.subdomain,
          locale: this.locale,
        },
      }).then(res => {
        this.$store.state.auth.email_verification.username = this.form.email;
        this.$store.state.auth.email_verification.uuid = res.uuid;
        return this.$router.push({ name: 'email_verification' });
      }, () => {
        this.error = this.$t('public.signup.account.error.message');
      }).finally(() => {
        this.loading = false;
      });
    },
    onCancel() {
      this.$router.push({ name: 'presentation' });
    },
    federatedSignIn(provider) {
      this.$router.push({ name: 'federated_start', query: { provider } });
    },
  },
};
</script>

<style scoped>
.sign_up-card {
  border-radius: 15px;
}

.border-with-text {
  width: 100%;
  text-align: center;
  border-bottom: 2px solid var(--StarlingLightGrey);
  color: var(--StarlingLightGrey);
  line-height: 0.1em;
  margin: 10px 0;
}

.border-with-text span {
  background: #fff;
  padding: 0 10px;
}

.social_login {
  min-height: 45px;
  border-radius: 10px;
}
</style>
